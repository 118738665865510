<template>
  <mf-loading loader-type="dialog" :boolean-loader="$apollo.queries.criteria.loading">
    <base-page-layout title="Critério de Segmento">
      <v-card>
        <v-card-title>
          {{ id ? 'Editar critério' : 'Novo critério' }}
        </v-card-title>
        <v-card-subtitle v-if="id" class="d-flex flex-column">
          <span>Alterado em: {{ updated_at | dateTime }}</span>
          <span>Alterado por: {{ updated_by_email ? updated_by_email : '-' }}</span>
        </v-card-subtitle>
        <v-card-text>
          <form>
            <v-text-field
              v-model="name"
              :error-messages="nameErrors"
              label="Nome"
              required
              outlined
              hint="Esse será o mesmo nome usado para preencher o template na coluna segmento_tipo."
              persistent-hint
              maxlength="70"
              counter
              @input="$v.name.$touch()"
              @blur="$v.name.$touch()"
            />

            <v-text-field
              v-model="description"
              :error-messages="descriptionErrors"
              label="Descrição"
              required
              outlined
              maxlength="200"
              counter
              @input="$v.description.$touch()"
              @blur="$v.description.$touch()"
            />

            <select-industry ref="industrySelection" v-model="industry" hint="Indústria na qual foram cadastrados os segmentos." persistent-hint required />

            <mf-card-container
              v-model="search"
              placeholder="Busca"
              use-search
              title="Segmentos"
              :description="descriptionSelectedSegment"
              style="box-shadow: none; padding: 14px !important"
            >
              <v-data-table
                v-model="selectedSegment"
                :loading="$apollo.queries.segments.loading"
                :headers="headers"
                :items="segments"
                :search="search"
                :options.sync="options"
                :server-items-length="dataCount"
                :items-per-page="5"
                :footer-props="{ itemsPerPageOptions: [10, 15, 20] }"
                item-key="_id"
                show-select
                single-select
              >
                <template v-slot:top>
                  <span class="error-class">{{ showSegmentError ? 'É necessário selecionar um segmento' : '' }}</span>
                </template>
              </v-data-table>
            </mf-card-container>
          </form>
        </v-card-text>
      </v-card>

      <div class="d-flex flex-row align-center justify-end mt-2" style="gap: 8px;">
        <mf-button label="Cancelar" outlined color="error" @click="() => $router.push({ name: 'criterios-segmento' })" />
        <mf-action-buttons
          :primary-button="{
            text: id ? 'Salvar' : 'Inserir',
            action: submit,
            isVisible: true,
            isDisabled: false,
            isLoading: loading
          }"
        />
      </div>
    </base-page-layout>
  </mf-loading>
</template>

<script>
import { required } from 'vuelidate/lib/validators'
import { QUERY_INDUSTRY_SEGMENT_CRITERIA_BY_ID, MUTATION_UPSERT_INDUSTRY_SEGMENT_CRITERIA, QUERY_SEGMENT_BY_INDUSTRY_ID } from '@/modules/industry/graphql'

export default {
  components: {
    BasePageLayout: () => import('@/components/base/BasePageLayout.vue'),
    SelectIndustry: () => import('@/modules/industry/components/SelectIndustry.vue')
  },
  props: {
    id: {
      type: String,
      default: ''
    }
  },
  data: () => ({
    loading: false,
    search: '',
    dataCount: 0,
    options: {},
    name: '',
    description: '',
    industry: {},
    selectedSegment: [],
    updated_at: Object,
    updated_by_email: '',
    showSegmentError: false
  }),
  computed: {
    headers() {
      return [
        {
          text: 'Nome',
          value: 'name',
          sortable: true,
          filterable: true
        },
        {
          text: 'Descrição',
          value: 'description',
          sortable: true,
          filterable: true
        }
      ]
    },

    descriptionSelectedSegment() {
      return this.selectedSegment.length > 0
        ? `Segmento selecionado: ${this.selectedSegment[0].name}, ${this.selectedSegment[0].description}`
        : 'Nenhum segmento selecionado'
    },

    nameErrors() {
      const errors = []
      if (!this.$v.name.$dirty) return errors
      !this.$v.name.required && errors.push('Nome é obrigatório.')
      return errors
    },
    descriptionErrors() {
      const errors = []
      if (!this.$v.description.$dirty) return errors
      !this.$v.description.required && errors.push('Descrição é obrigatória.')
      return errors
    }
  },
  apollo: {
    criteria: {
      query: QUERY_INDUSTRY_SEGMENT_CRITERIA_BY_ID,
      context() {
        return {
          headers: {
            authorization: localStorage.getItem('session_id')
          },
          uri: this.$microservicesUrls['accounts']
        }
      },
      variables() {
        return { input: { indSegmentCriteriaId: this.id } }
      },
      skip() {
        return !this.id
      },
      update(result) {
        const criteria = result.industrySegmentCriteria
        this.name = criteria.name
        this.description = criteria.description
        this.industry = criteria.industry
        this.updated_at = criteria.updated_at
        this.updated_by_email = criteria.updated_by_email
        this.selectedSegment = [criteria.reference_segment]
        return criteria
      }
    },
    segments: {
      query: QUERY_SEGMENT_BY_INDUSTRY_ID,
      context() {
        return {
          headers: {
            authorization: localStorage.getItem('session_id')
          },
          uri: this.$microservicesUrls['accounts']
        }
      },
      variables() {
        return {
          input: {
            industryId: this.industry._id
          },
          pagination: {
            page: this.options.page || 1,
            pageSize: this.options.itemsPerPage || 10,
            search: this.search || null,
            sort: this.options.sortBy || '',
            sortDirection: this.options.sortDesc && this.options.sortDesc.map(direction => (direction ? 'DESC' : 'ASC'))
          }
        }
      },
      skip() {
        return !this.industry._id
      },
      update(result) {
        const { count, data } = result.listSegmentsByIndustry
        this.dataCount = count
        return data
      }
    }
  },
  watch: {
    selectedSegment() {
      this.showSegmentError = false
    },
    search() {
      this.options.page = 1
    }
  },
  methods: {
    async submit() {
      this.showSegmentError = false
      this.$v.$touch()
      this.$refs.industrySelection.$v.$touch()
      if (this.selectedSegment.length <= 0) {
        this.showSegmentError = true
      }

      if (this.$v.$invalid || this.selectedSegment.length <= 0 || !this.$refs.industrySelection.valid()) return

      this.loading = true
      let mutation = MUTATION_UPSERT_INDUSTRY_SEGMENT_CRITERIA
      let variables = {
        input: {
          name: this.name,
          description: this.description,
          segment_id: this.selectedSegment[0]._id,
          industry_id: this.industry._id
        }
      }
      if (this.id) {
        variables.input._id = this.id
      }

      try {
        await this.$apollo.mutate({
          mutation,
          variables,
          context: {
            uri: this.$microservicesUrls['accounts'],
            headers: {
              authorization: localStorage.getItem('session_id')
            }
          }
        })
        this.$snackbar({ message: 'Critério salvo com sucesso', snackbarColor: 'success' })
        this.$router.push({ name: 'criterios-segmento' })
      } catch (error) {
        this.$alert({
          alert_message: 'Erro ao adicionar critério',
          alert_title: 'Erro!',
          alert_color: 'error',
          alert_icon: 'mdi-close-circle',
          action: () => {
            const { errors } = error.networkError.result
            this.$errorDialog({
              title: errors.length > 1 ? 'Detalhes dos erros' : 'Detalhes do erro',
              errors: errors
            })
          },
          action_label: 'Ver detalhes'
        })

        if (error.graphQLErrors && error.graphQLErrors.length > 0) {
          const graphQLError = error.graphQLErrors[0]
          const details = graphQLError.message || 'Detalhes do erro indisponíveis'
          console.error(details)
        }
      } finally {
        this.loading = false
      }
    }
  },
  validations: {
    name: { required },
    description: { required }
  }
}
</script>

<style lang="scss" scoped>
.error-class {
  font-size: 12px;
  color: #ff5252;
}
</style>
